import React, { useState } from "react";
import CiscoLogoSVG from "../../assets/images/cisco-logo.svg";
import DNASpacesLogoSVG from "../../assets/images/dnaspaces-logo.svg";
import WebexLogoSVG from "../../assets/images/webex-logo.svg";
import WebexSVG from "../../assets/images/webex.svg";
import "./Delete.css";
import { DataService } from "../../utils/DataService";
import { Constants } from "../../models/constants";
import Footer from "../footer/Footer";

function Delete() {
  const DataServiceObj = new DataService();
  const [showWebex, setShowWebex] = useState(false);
  DataServiceObj.getDeviceType().then((deviceType) => {
    setShowWebex(deviceType === Constants.DEVICE.WEBEX);
  });

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}></div>
      <Footer />
    </>
  );
}

export default Delete;
